export default [
    {
        "id": 0,
        "name": "Ryu",
        "smallImg": "http://street-crescer.surge.sh/images/characters/ryu_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/ryu.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Hadouken",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Super Hadouken",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 1,
        "name": "Ken",
        "smallImg": "http://street-crescer.surge.sh/images/characters/ken_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/ken.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Shoryuken",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Shoryu-Reppa",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 2,
        "name": "Chun-Li",
        "smallImg": "http://street-crescer.surge.sh/images/characters/chun_li_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/chun_li.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Houyouku-Sen",
                "damage": 40,
                "turnsToUse": 3
            }
        ]
    },
    {
        "id": 3,
        "name": "Dee Jay",
        "smallImg": "http://street-crescer.surge.sh/images/characters/dj_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/dj.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Double Rolling Sobat",
                "damage": 10,
                "turnsToUse": 2
            },
            {
                "name": "Climax Beast",
                "damage": 30,
                "turnsToUse": 5
            },
            {
                "name": "Air Slasher",
                "damage": 30,
                "turnsToUse": 8
            }
        ]
    },
    {
        "id": 4,
        "name": "Cammy",
        "smallImg": "http://street-crescer.surge.sh/images/characters/cammy_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/cammy.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Cannon Drill",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Super Cannon Drill",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 5,
        "name": "Sagat",
        "smallImg": "http://street-crescer.surge.sh/images/characters/sagat_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/sagat.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Tiger Shot",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Tiger Genocide",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 6,
        "name": "Zangief",
        "smallImg": "http://street-crescer.surge.sh/images/characters/zangief_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/zangief.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Spinning Pile Driver",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Final Atomic Buster",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 7,
        "name": "Akuma",
        "smallImg": "http://street-crescer.surge.sh/images/characters/akuma_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/akuma.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Ashura Senku Backward",
                "damage": 20,
                "turnsToUse": 3
            },
            {
                "name": "Messatsu Gohado",
                "damage": 30,
                "turnsToUse": 8
            },
            {
                "name": "Raging Demon",
                "damage": 60,
                "turnsToUse": 10
            }
        ]
    },
    {
        "id": 8,
        "name": "Blanka",
        "smallImg": "http://street-crescer.surge.sh/images/characters/blanka_headshot.jpg",
        "largeImg": "http://street-crescer.surge.sh/images/characters/blanka.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Horizontal Ball",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Super Ball",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    },
    {
        "id": 9,
        "name": "Silvio",
        "smallImg": "http://street-crescer.surge.sh/images/characters/silvio_headshot.png",
        "largeImg": "http://street-crescer.surge.sh/images/characters/silvio.png",
        "skills": [
            {
                "name": "Punch",
                "damage": 5,
                "turnsToUse": 0
            },
            {
                "name": "Ai, ai, ai, ui, ui",
                "damage": 20,
                "turnsToUse": 2
            },
            {
                "name": "Maoe",
                "damage": 40,
                "turnsToUse": 5
            }
        ]
    }
]